import React from 'react'
import Layout from '../components/Layout'
import PageHero from '../components/PageHero/PageHero'
import List from '../components/List/List'
import ListItem from '../components/List/ListItem'
import { graphql } from 'gatsby'
import Spacer from '../components/Spacer'
import Seo from '../components/Seo'

const prislista = ({ data: { page } }) => {
  const pricelist = page.prislista.prisgrupp
  const image = page.hero.bild
  return (
    <Layout>
      <Seo
        title={'Prislista'}
        description={
          'Våra frisörer har olika priser beroende på vår kunskap, erfarenhet och beläggningsgrad. Hitta oss på www.bokadirekt.se "Gula Markiserna" Åkersberga, Stockholm.'
        }
      />
      <PageHero title={'Prislista'} src={image} />

      <Spacer />
      <div className="container ">
        <div className="mx-auto max-w-3xl">
          {pricelist?.length &&
            pricelist.map((item, index) => (
              <List key={index} title={item.rubrik}>
                {item.pris.map((item, index) => (
                  <ListItem key={index} first={item.text} second={item.pris} />
                ))}
              </List>
            ))}
        </div>
        <Spacer />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PricePage {
    page: wpPage(title: { eq: "Prislista" }) {
      id
      hero {
        bild {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      prislista {
        prisgrupp {
          rubrik
          pris {
            text
            pris
          }
        }
      }
    }
  }
`

export default prislista
