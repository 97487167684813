import React from 'react'
import './PageHero.scss'

const PageHero = ({ title, small, hScreen }) => {
  return (
    <>
      <div className="container">
        <header
          className={`page-hero ${small ? 'small' : ''} ${
            hScreen ? 'full-height' : ''
          }`}
        >
          <div className="inner container">
            <h1 className="h3">{title}</h1>
          </div>
        </header>
      </div>
    </>
  )
}

export default PageHero
