import React from 'react'
import { motion } from 'framer-motion'

import './ListItem.scss'

const item = {
  hidden: { opacity: 0, y: -10 },
  show: { opacity: 1, y: 0 },
}

const ListItem = ({ first, second }) => {
  return (
    <motion.li variants={item} className="list-item">
      <span>{first}</span>
      <span>{second}</span>
    </motion.li>
  )
}

export default ListItem
