import React from 'react'
import { motion } from 'framer-motion'
import './List.scss'
import Spacer from '../Spacer'

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const List = ({ children, title }) => {
  return (
    <>
      {title && (
        <div className="w-full text-center">
          <h3 className="h4">{title}</h3>
          <Spacer />
        </div>
      )}
      <motion.ul
        variants={container}
        initial="hidden"
        animate="show"
        className={`list-wrapper`}
      >
        {children}
      </motion.ul>
      <Spacer />
    </>
  )
}

export default List
